<template>
  <div ref="ganttContainer"
  :show_grid="false"></div>
</template>

<script>
import { gantt } from 'dhtmlx-gantt'

const zoomConfig = {
  levels: [
    {
      name: 'day',
      scale_height: 27,
      min_column_width: 80,
      scales: [
        { unit: 'day', step: 1, format: '%d %M' }
      ]
    },
    {
      name: 'week',
      scale_height: 50,
      min_column_width: 50,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: function(date) {
            var dateToStr = gantt.date.date_to_str('%d %M')
            var endDate = gantt.date.add(date, +6, 'day')
            var weekNum = gantt.date.date_to_str('%W')(date)
            return 'Week #' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate)
          }
        },
        { unit: 'day', step: 1, format: '%j %D' }
      ]
    },
    {
      name: 'month',
      scale_height: 50,
      min_column_width: 120,
      scales: [
        { unit: 'month', format: '%F, %Y' },
        { unit: 'week', format: 'Week #%W' }
      ]
    },
    {
      name: 'quarter',
      height: 50,
      min_column_width: 90,
      scales: [
        { unit: 'month', step: 1, format: '%M' },
        {
          unit: 'quarter',
          step: 1,
          format: function(date) {
            var dateToStr = gantt.date.date_to_str('%M')
            var endDate = gantt.date.add(gantt.date.add(date, 3, 'month'), -1, 'day')
            return dateToStr(date) + ' - ' + dateToStr(endDate)
          }
        }
      ]
    },
    {
      name: 'year',
      scale_height: 50,
      min_column_width: 30,
      scales: [
        { unit: 'year', step: 1, format: '%Y' }
      ]
    }
  ],
  useKey: 'altKey',
  trigger: 'wheel',
  element: function() {
    return gantt.$root.querySelector('.gantt_task')
  }
}

export default {
  props: {
    tasks: {
      type: Object,
      default() {
        return { data: [], links: [] }
      }
    },
    startDate: {
      type: Date,
      default() {
        return { startDate: new Date() }
      }
    },
    endDate: {
      type: Date,
      default() {
        return { endDate: new Date() }
      }
    },
    columns: {
      type: [],
      default() {
        return { columns: [] }
      }
    }
  },

  mounted: function() {
    gantt.config.date_format = '%Y-%m-%d'
    gantt.config.show_progress = false
    gantt.config.show_links = true
    gantt.config.drag_move = false
    gantt.config.drag_links = false
    gantt.config.drag_resize = false
    gantt.config.drag_progress = false
    gantt.ext.zoom.init(zoomConfig)
    gantt.ext.zoom.setLevel('week')
    gantt.config.show_task_cells = false

    gantt.attachEvent('onTaskDblClick', (id, link) => {
      var taskObj = gantt.getTask(id)
      this.$emit('task-double-click', taskObj)
    })

    gantt.config.columns = this.columns
  }
}
</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
@import "~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css";
</style>
